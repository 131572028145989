import { useEffect, useState } from 'react';
import { Flex, Popover, message, Button, Space, Row, Typography } from 'antd';
import DictionaryPop from './DictionaryPop';
import TextRow from './TextRow';
import AudioPlayer from '../common/AudioPlayer';
import { DocumentDto, Word } from '../../data/interfaces/document';
import { FlashcardDto } from '../../data/interfaces/flashcard';
import { editText, getAudioUrl, getFlashcards, setBookmark } from '../../utils/supabase';
import axiosInstance from '../../utils/axiosInstance';

interface FlashcardsData {
  id: string;
  title: string;
  flashcards: FlashcardDto[];
}

interface RProps {
  doc?: DocumentDto, 
  items: string[], 
  callback: (payload: DocumentDto) => void, 
  bookIndex: number | undefined,
  setBookIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
  refs: React.MutableRefObject<(HTMLDivElement | null)[]>;
}

const { Text, Title } = Typography;

const TextTabContent = ({ doc, items, callback, refs, bookIndex, setBookIndex }: RProps) => {
  const [selectedWord, setSelectedWord] = useState<Word | null>(null);
  const [selectedText, setSelectedText] = useState<string>('');
  const [selectedFullText, setSelectedFullText] = useState<string>('');
  const [selectedMeaning, setSelectedMeaning] = useState<string>('');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMeaningVisible, setPopupMeaningVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [flashcardsData, setFlashcardsData] = useState<FlashcardsData[]>([]);
  const [audio, setAudio] = useState<string>('');

  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);
    document.addEventListener('mousedown', handleClickOutside);

    fetchData();
    setAudioURL();

    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [doc]);

  const fetchData = async () => {
    const response: FlashcardsData[] = await getFlashcards();;
    setFlashcardsData(response);
  }

  const setAudioURL = async () => {
    if (doc?.json_structure?.content?.audio_url) {
      const url = await getAudioUrl(doc?.json_structure?.content?.audio_url)
      if (url) {
        setAudio(url);
      }
    }
  }

  const handleWordClick = (word: Word, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (word.partOfSpeech === 'punctuation') {
      return;
    }
    const rect = event.currentTarget.getBoundingClientRect();

    const popoverLeft = window.innerWidth - rect.left < 200 
      ? Math.max(0, rect.left + window.scrollX - 200) 
      : rect.left + window.scrollX;

    const popoverTop = window.innerHeight - rect.top < 200
      ? Math.max(rect.top + window.scrollY - 200)
      : rect.top + window.scrollY + 20;

    setPopoverPosition({
      top: popoverTop,
      left: popoverLeft,
    });

    setSelectedWord(word);
    setPopoverVisible(true);
  }

  const handleVisibleChange = (open: any) => {
    setPopoverVisible(open)
  }

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const isPopoverClick = target.closest('.ant-popover');
    
    if (!isPopoverClick) {
      setPopupVisible(false);
      setPopupMeaningVisible(false);
    }
  };

  const handleTextSelection = () => {
    const selection = window.getSelection();
    
    if (!selection || selection.rangeCount === 0) {
      return;
    }
    
    const selectedRange = selection.getRangeAt(0);
    
    if (selectedRange) {
      const selectedTextNodes = Array.from(selectedRange.cloneContents().querySelectorAll('.selectable'));

      if (selectedTextNodes.length > 0) {
        const selText = selectedTextNodes.map(node => node.textContent).join('');
        setSelectedText(selText);

        const sentences = document.querySelectorAll('.sentence');
        let fullSentenceText = '';

        sentences.forEach((sentence) => {
          const sentenceRange = document.createRange();
          sentenceRange.selectNodeContents(sentence);

          if (
            selectedRange.compareBoundaryPoints(Range.START_TO_END, sentenceRange) > -1 &&
            selectedRange.compareBoundaryPoints(Range.END_TO_START, sentenceRange) < 1
          ) {
            const sentenceText = Array.from(sentence.querySelectorAll('.selectable'))
              .map(node => node.textContent)
              .join('');
            fullSentenceText += sentenceText + ' ';
          }
        })

        fullSentenceText = fullSentenceText.trim();
        setSelectedFullText(fullSentenceText);

        const range = selectedRange.getBoundingClientRect();
        setPopupPosition({
          left: (range.left + range.right) / 2 - 60,
          top: range.top + window.scrollY - 60
        });
        setPopupVisible(true);
      } else {
        setPopupVisible(false);
      }
    } else {
      setPopupVisible(false);
    }
  }

  const isFlashcard = (word: Word): number => {
    if (doc?.flashcards?.find(f => f.word === word.text)) {
      return 1;
    } else {
      let added = false;
      for (let index = 0; index < flashcardsData.length; index++) {
        const element = flashcardsData[index];
        
        if (element.flashcards.find(f => f.word === word.text)) {
          added = true;
          break;
        }
      }

      if (added) {
        return 2;
      } else {
        return 0;
      }
    }
  }

  const handleFlashcard = async (word: Word, adding: boolean) => {
    try {
      if (doc) {
        if (adding) {
          const newFlashcard: FlashcardDto = {
            word: word.text,
            pinyin: word.pinyin,
            audio_url: word.audio_url,
            part_of_speech: word.partOfSpeech,
            short_definitions: word.translation ? [word.translation] : []
          }
    
          if (doc.flashcards) {
            doc.flashcards?.push(newFlashcard);
          } else {
            doc.flashcards = [newFlashcard];
          }
        } else {
          doc.flashcards = doc.flashcards?.filter(f => f.word !== word.text)
        }

        await editText(doc, doc.id);
        callback(doc);
        setPopoverVisible(false);
      }
    } catch (error) {
      message.error((error as Error).message);
    }
  }
  
  const getExplain = async (type: string) => {
    try {
      const data = {
        fullSentence: selectedFullText,
        targetPart: selectedText,
        type
      }
      const response = await Promise.race([
        axiosInstance.post('/api/get-grammar-or-meaning', data),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
      ])
    
      if (response && typeof response === 'object' && 'data' in response) {
        setSelectedMeaning(response.data as string);
        setPopupVisible(false);
        setPopupMeaningVisible(true);
      }
    } catch (error) {
      console.log((error as Error).message);
    }
  }

  const handleBookmark = async (index: number) => {
    if (doc) {
      try {
        await setBookmark(doc.id, index);
        setBookIndex(index);
      } catch (error) {
        message.error((error as Error).message);
      }
    }
  }

  return (
    <Flex className='container' gap={16} vertical>
      <Space className='w-full bg-white flex-col py-4 round-8'>
        {audio && (
          <Row className='m-4'>
            <AudioPlayer url={audio} />
          </Row>
        )}
        <Flex vertical gap={16}>
          {doc?.json_structure?.content?.paragraphs.filter(p => p.sentences?.length && p.sentences?.length > 0).map((p, p_index) => (
            <TextRow
              key={p_index}
              index={p_index}
              ref={(el: any) => (refs.current[p_index] = el)}
              showChinese={items.includes('chinese')}
              showPinyin={items.includes('pinyin')}
              showEnglish={items.includes('english')}
              paragraph={p}
              isBookmarked={p_index === bookIndex}
              handleWordClick={handleWordClick}
              handleBookmark={handleBookmark}
            />
          ))}
        </Flex>
      </Space>

      <Popover
        content={selectedWord && <DictionaryPop word={selectedWord} handleFlashcard={handleFlashcard} isFlashcard={isFlashcard(selectedWord)} />}
        open={popoverVisible}
        trigger={["click"]}
        onOpenChange={handleVisibleChange}
        placement='bottom'
        forceRender={true}
        arrow={false}
        overlayStyle={{
          position: 'absolute',
          top: `${popoverPosition.top}px`,
          left: `${popoverPosition.left}px`,
          height: 'fit-content',
        }}
        motion={{}}
      />

      <Popover
        content={selectedText && (
          <Flex gap={16}>
            <Button className='border-none shadow-none' onClick={() => getExplain('grammar')}>Grammar</Button>
            <Button className='border-none shadow-none' onClick={() => getExplain('meaning')}>Meaning</Button>
          </Flex>
        )}
        open={popupVisible}
        trigger={["click"]}
        onOpenChange={(open) => setPopupVisible(open)}
        placement='top'
        arrow={false}
        forceRender={true}
        overlayStyle={{
          position: 'absolute',
          top: `${popupPosition.top}px`,
          left: `${popupPosition.left}px`,
          height: 'fit-content',
        }}
        motion={{}}
      />

      <Popover
        content={selectedMeaning && (
          <Flex vertical>
            <Title level={5}>Explanation</Title>
            <Text>{selectedMeaning}</Text>
          </Flex>
        )}
        open={popupMeaningVisible}
        trigger={["click"]}
        onOpenChange={(open) => setPopupMeaningVisible(open)}
        placement='top'
        arrow={false}
        forceRender={true}
        overlayStyle={{
          width: '400px',
          position: 'absolute',
          top: `${popupPosition.top}px`,
          left: `${popupPosition.left}px`,
          height: 'fit-content',
        }}
        motion={{}}
      />
    </Flex>
  );
};

export default TextTabContent;