export enum FeedType {
  TRANSLATE = 'translate',
  READ = 'read',
  ANSWER = 'answer',
  LISTEN_SPEAK = 'listen_speak',
  WRITE = 'write',
  PICK = 'pick',
  SPEAK = 'speak',
  LISTEN_RESPOND = 'listen_respond',
  ENGLISH_TO_CHINESE = 'english_to_chinese',
  CHINESE_TO_ENGLISH = 'chinese_to_english',
  WORD = 'word'
}

export interface FeedInterface {
  id: string;
  type: FeedType;
  text?: string;
  question?: string;
  options?: string[];
  audio?: string;
  word?: string;
}
