import { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { supabase } from '../supabaseClient';
import { createConversation, updateConversation } from '../utils/supabase';

type ConnectionStatus = 'disconnected' | 'connecting' | 'connected' | 'error' | 'listening' | 'processing';

interface WebRTCProps {
  documentContext?: {
    text: string;
    title: string;
    id: string;
  };
}

interface Message {
  role: 'user' | 'assistant';
  content: string;
  interim?: boolean;
}

interface ConversationData {
  document_id: string;
  messages: Message[];
  status: 'active' | 'completed';
  end_timestamp?: string;
}

export const useWebRTC = ({ documentContext }: WebRTCProps = {}) => {
  const [status, setStatus] = useState<ConnectionStatus>('disconnected');
  const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null);
  const [dataChannel, setDataChannel] = useState<RTCDataChannel | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversationId, setConversationId] = useState<string | null>(null);

  const sendSessionUpdate = (dc: RTCDataChannel) => {
    const event = {
      type: "session.update",
      session: {
        modalities: ["text", "audio"],
        instructions: documentContext ? `You are a Chinese language tutor. The current text we are studying is titled "${documentContext.title}":

${documentContext.text}

Help the student understand this text by:
- Explaining vocabulary and grammar when asked
- Providing cultural context when relevant
- Answering questions about the text
- Helping with pronunciation and understanding

Please be clear and concise in your explanations, like a language teacher would be.` : "You are a helpful assistant",
        input_audio_format: "pcm16",
        output_audio_format: "pcm16",
        input_audio_transcription: {
          model: "whisper-1"
        },
        turn_detection: {
          type: "server_vad",
          threshold: 0.5,
          prefix_padding_ms: 300,
          silence_duration_ms: 1000,
          create_response: true
        },
        temperature: 0.8,
        max_response_output_tokens: 4096
      }
    };

    try {
      if (dc.readyState === 'open') {
        dc.send(JSON.stringify(event));
        console.log('Session update sent:', event);
      } else {
        console.error('Data channel not open:', dc.readyState);
        setStatus('error');
      }
    } catch (error) {
      console.error('Error sending session update:', error);
      setStatus('error');
    }
  };

  const initializeConversation = async () => {
    if (documentContext?.id) {
      try {
        const data = await createConversation(documentContext.id);
        if (data?.id) {
          setConversationId(data.id);
        }
      } catch (error) {
        console.error('Failed to create conversation:', error);
      }
    }
  };

  const saveConversation = async () => {
    if (conversationId && messages.length > 0) {
      try {
        await updateConversation(conversationId, messages, 'completed');
      } catch (error) {
        console.error('Failed to save conversation:', error);
      }
    }
  };

  const initConnection = async () => {
    try {
      setStatus('connecting');
      await initializeConversation();
      
      // Add leading slash to ensure absolute path
      const tokenResponse = await axiosInstance.post("/api/generate-ephemeral-key");
      const ephemeralKey = tokenResponse.data.ephemeral_key;

      // Create peer connection
      const pc = new RTCPeerConnection();
      setPeerConnection(pc);

      // Set up audio element for model's voice
      const audioEl = window.document.createElement("audio");
      audioEl.autoplay = true;
      pc.ontrack = e => audioEl.srcObject = e.streams[0];

      // Set up microphone
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      pc.addTrack(mediaStream.getTracks()[0]);

      // Required data channel setup
      const dataChannel = pc.createDataChannel("oai-events");
      setDataChannel(dataChannel);
      
      // Handle data channel state changes
      dataChannel.onopen = () => {
        console.log('Data channel open');
        setStatus('connected');
        // Add a small delay before sending the session update
        setTimeout(() => {
          sendSessionUpdate(dataChannel);
        }, 1000);
      };

      dataChannel.onclose = () => {
        console.log('Data channel closed');
        setStatus('disconnected');
      };

      dataChannel.onerror = (error) => {
        console.error('Data channel error:', error);
        setStatus('error');
      };
      
      dataChannel.addEventListener("message", (e) => {
        const message = JSON.parse(e.data);
        console.log('Received message:', message);
        console.log('Message type:', message.type);
        
        // Add handlers for speech detection events
        if (message.type === 'session.created') {
          console.log('Session created with config:', message.session);
        }

        if (message.type === 'session.updated') {
          console.log('Session updated with config:', message.session);
        }

        if (message.type === 'input_audio_buffer.speech_started') {
          console.log('User started speaking');
          setStatus('listening');
        }

        if (message.type === 'input_audio_buffer.speech_stopped') {
          console.log('User stopped speaking');
          setStatus('processing');
        }

        if (message.type === 'input_audio_buffer.committed') {
          console.log('Audio buffer committed:', message.buffer);
        }

        if (message.type === 'conversation.item.created') {
          console.log('Conversation item created:', message.item);
        }

        // Handle user transcripts
        if (message.type === 'conversation.item.input_audio_transcription.completed') {
          const finalTranscript = message.transcript || '';
          console.log('User transcript completed:', finalTranscript);
          
          if (finalTranscript.trim()) {
            setMessages(prev => [...prev, {
              role: 'user',
              content: finalTranscript.trim()
            }]);
          }
        }

        // Handle model transcripts - only show final versions
        if (message.type === 'response.audio_transcript.done') {
          console.log('Model transcript completed:', message.transcript);
          if (message.transcript?.trim()) {
            setMessages(prev => [...prev, {
              role: 'assistant',
              content: message.transcript.trim()
            }]);
          }
        }
      });

      // Create and send offer
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      const baseUrl = "https://api.openai.com/v1/realtime";
      const model = "gpt-4o-realtime-preview-2024-12-17";
      const queryParams = new URLSearchParams({
        model: model,
        input_audio_transcription: JSON.stringify({
          model: "whisper-1",
        })
      });
      
      const sdpResponse = await fetch(`${baseUrl}?${queryParams}`, {
        method: "POST",
        body: offer.sdp,
        headers: {
          Authorization: `Bearer ${ephemeralKey}`,
          "Content-Type": "application/sdp"
        }
      });

      if (!sdpResponse.ok) {
        throw new Error('Failed to connect to OpenAI');
      }

      const answer = {
        type: "answer" as RTCSdpType,
        sdp: await sdpResponse.text(),
      };
      
      await pc.setRemoteDescription(answer);

    } catch (error) {
      console.error('Connection error:', error);
      setStatus('error');
    }
  };

  const cleanup = async () => {
    if (peerConnection) {
      await saveConversation();

      // Close all tracks
      peerConnection.getSenders().forEach(sender => {
        if (sender.track) {
          sender.track.stop();
        }
      });
      
      // Close the peer connection
      peerConnection.close();
      setPeerConnection(null);
      setStatus('disconnected');
    }
  };

  useEffect(() => {
    initConnection();
    // Cleanup when component unmounts
    return () => {
      cleanup();
    };
  }, []);

  return { status, cleanup, messages };
};