import { Navigate, RouteObject } from 'react-router-dom';
import DocumentDetailPage from './pages/documents/detail';
import DocumentsPage from './pages/documents/list';
import ExercisesPage from './pages/exercises/list';
import ExerciseDetailPage from './pages/exercises/detail';
import PrivateRoute from './components/auth/PrivateRoute';
import Page404 from './pages/404';
import AuthPage from './pages/auth';
import ForgotPasswordPage from './pages/auth/forgot-password';
import ResetPasswordPage from './pages/auth/reset-password';
import DictionaryPage from './pages/dictionary';
import DictionaryDetailPage from './pages/dictionary/detail';
import FlashcardsPage from './pages/flashcards/list';
import DocumentFlashcardsPage from './pages/documents/flashcards';
import ProfilePage from './pages/profile';
import FeedPage from './pages/feed';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to='/login' />
  },
  {
    path: '/login',
    element: <AuthPage />
  },
  {
    path: '/register',
    element: <AuthPage />
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />
  },
  {
    path: '/profile',
    element: <PrivateRoute><ProfilePage /></PrivateRoute>
  },
  {
    path: '/documents',
    element: <PrivateRoute><DocumentsPage /></PrivateRoute>
  },
  {
    path: '/documents/:id',
    element: <PrivateRoute><DocumentDetailPage /></PrivateRoute>
  },
  {
    path: '/documents/:id/flashcards',
    element: <PrivateRoute><DocumentFlashcardsPage /></PrivateRoute>
  },
  {
    path: '/exercises/:documentId',
    element: <PrivateRoute><ExercisesPage /></PrivateRoute>
  },
  {
    path: '/exercises/:documentId/:id',
    element: <PrivateRoute><ExerciseDetailPage /></PrivateRoute>
  },
  {
    path: '/dictionary',
    element: <PrivateRoute><DictionaryPage /></PrivateRoute>
  },
  {
    path: '/dictionary/:id',
    element: <PrivateRoute><DictionaryDetailPage /></PrivateRoute>
  },
  {
    path: '/flashcards',
    element: <PrivateRoute><FlashcardsPage /></PrivateRoute>
  },
  {
    path: '/feed',
    element: <PrivateRoute><FeedPage /></PrivateRoute>
  },
  {
    path: '*',
    element: <Page404 />
  }
];

export default routes;
