import { Flex, Typography } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import { phrasesData } from "../../data/mockUp";

const { Title, Text } = Typography;

const PhraseTabContent = () => {

  return (
    <Flex vertical className="container">
      {phrasesData.map(p => (
        <Flex key={p.id} vertical className="w-full phrase-tab" gap={4}>
          <Title level={5} className="mb-0">
            {`${p.text} ${p.pinyin ?? ''} `}
            {p.audio_url && <SoundOutlined className="sound-icon" />} 
          </Title>
          <Text>{p.translation}</Text>
        </Flex>
      ))}
    </Flex>
  )
};

export default PhraseTabContent;