import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Layout as AntLayout, Avatar, Dropdown, Flex, Image, Menu, MenuProps } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { UserOutlined, LogoutOutlined, BookOutlined, MenuOutlined, BankOutlined, TranslationOutlined, CloseOutlined, DatabaseOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { RootState } from "../../store";
import { signOut } from '../../utils/supabase';
import LogoIcon from "../../assets/logo.svg";
import "./layout.css";

const { Header } = AntLayout;

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [selectedTab, setSelectedTab] = useState<string>('library');
  const [collapsed, setCollapsed] = useState<boolean>(true);

  type MenuItem = Required<MenuProps>['items'][number];

  const handleLogout = async () => {
    try {
      await signOut(dispatch);
    } catch (error) {
    }
  }

  const goToAccount = () => {
    navigate('profile');
  }

  const handleMenuClick = (e: any) => {
    setSelectedTab(e.key);
    setCollapsed(true);
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  }

  const handleLogoClick = () => {
    navigate('documents');
  }

  const items: MenuProps['items'] = [
    {
      key: 0,
      icon: <UserOutlined />,
      label: 'Account',
      onClick: goToAccount
    },
    {
      key: 1,
      icon: <LogoutOutlined />,
      label: 'Logout',
      onClick: handleLogout
    }
  ]

  const menuItems: MenuItem[] = [
    {
      label: (<Link to='/documents'>Library</Link>),
      key: 'library',
      icon: <BankOutlined />,
    },
    {
      label: (<Link to='/dictionary'>Dictionary</Link>),
      key: 'dictionary',
      icon: <TranslationOutlined />
    },
    {
      label: (<Link to='/flashcards'>Flashcards</Link>),
      key: 'flashcards',
      icon: <BookOutlined />
    },
    {
      label: (<Link to='/feed'>Feed</Link>),
      key: 'feed',
      icon: <DatabaseOutlined />
    },
  ]

  return (
    <Header className="flex justify-between align-center header">
      <Flex gap={24} align='center'>
        <Flex gap={16} align='center' className='cursor-pointer'>
          {isMobile ? 
            collapsed ? 
              <MenuOutlined className='menu-item' onClick={toggleCollapsed} /> : 
              <CloseOutlined className='menu-item' onClick={toggleCollapsed} /> :
            <Image src={LogoIcon} alt='logo' width={32} preview={false} onClick={handleLogoClick} />
          }
          <Title level={4} className='mb-0 text-white' onClick={handleLogoClick}>Bao</Title>
        </Flex>
        {!isMobile && isAuthenticated && (
          <Menu 
            onClick={handleMenuClick} 
            selectedKeys={[selectedTab]} 
            mode='horizontal' 
            items={menuItems} 
            theme='dark'
            className='menu-authenticated'
          />
        )}
        {isMobile && !collapsed && (
          <Menu
            mode="inline"
            selectedKeys={[selectedTab]}
            inlineCollapsed={collapsed}
            items={menuItems}
            theme='dark'
            className='menu-collapsed'
            onClick={handleMenuClick}
          />
        )}
      </Flex>
      {isAuthenticated && (
        <Dropdown menu={{items}} trigger={['click']}>
          <Avatar className='avatar' icon={<UserOutlined />} />
        </Dropdown>
      )}
    </Header>
  );
};

export default HeaderComponent;
