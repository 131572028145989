import { useState } from 'react';
import { Button, Flex, Modal, Typography, Input, Checkbox, Space, notification, message, Spin, Upload, Progress } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ArrowLeftOutlined, InboxOutlined } from '@ant-design/icons';
import { AddNewTextDto, MaterialOptions, Status } from '../../data/interfaces/document';
import { addNewText, uploadImages } from '../../utils/supabase';
import axiosInstance from '../../utils/axiosInstance';
import type { UploadFile } from 'antd/es/upload/interface';

const { TextArea } = Input;
const { Text, Title } = Typography;
const { Dragger } = Upload;

interface DocumentAddModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  callback: (id: string, newDocumentDto: AddNewTextDto) => Promise<void>;
}

const DocumentAddModal = ({open, setOpen, callback}: DocumentAddModalProps) => {
  const [step, setStep] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForTitle, setLoadingForTitle] = useState<boolean>(false);
  const [materialOptions, setMaterialOptions] = useState<MaterialOptions>({
    audio: true,
    exercises: true,
    translation: true,
    wordsAndPhrases: true,
    pinyinAndDefinitions: true,
  })
  const [api, contextHolder] = notification.useNotification();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  
  const handleCheckboxChange = (e: CheckboxChangeEvent, option: string) => {
    e.stopPropagation();
    setMaterialOptions((prev) => ({
      ...prev,
      [option]: e.target.checked,
    }))
  }

  const handleSubmit = async () => {
    try {
      const newStatus: Status = {
        audio: materialOptions.audio ? 'needed' : 'not_needed',
        exercises: materialOptions.exercises ? 'needed' : 'not_needed',
        translation: materialOptions.translation ? 'needed' : 'not_needed',
        wordsAndPhrases: materialOptions.wordsAndPhrases ? 'needed' : 'not_needed',
        pinyinAndDefinitions: materialOptions.pinyinAndDefinitions ? 'needed' : 'not_needed',
      }
      const newDocumentDto: AddNewTextDto = {
        title: title,
        text: text,
        status: newStatus,
      }
  
      setLoading(true);
      const response = await addNewText(newDocumentDto);
      setLoading(false);
      setOpen(false);
      setTitle('');
      setStep(1);
      setText('');
      setMaterialOptions({
        audio: true,
        exercises: true,
        translation: true,
        wordsAndPhrases: true,
        pinyinAndDefinitions: true,
      })
      if (response.length > 0) {
        callback(response[0].id, newDocumentDto);
      }
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  const handleCancel = () => {
    setOpen(false);
    setTitle('');
    setStep(1);
    setText('');
    setMaterialOptions({
      audio: true,
      exercises: true,
      translation: true,
      wordsAndPhrases: true,
      pinyinAndDefinitions: true,
    })
  }

  const handleNextStep = async () => {
    try {
      setLoadingForTitle(true);
      setStep(2);
      const response = await Promise.race([
        axiosInstance.post('/api/suggest-title', { text: text.slice(0, 400) }),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 5000))
      ])

      if (response && typeof response === 'object' && 'data' in response) {
        setTitle(response.data as string);
      }
    } catch (error) {
      console.log((error as Error).message);
    }
    setLoadingForTitle(false);
  }

  const handleImageProcess = async () => {
    try {
      setUploading(true);
      
      // Make sure we have valid files with originFileObj
      const files = fileList
        .filter(file => file && file.originFileObj)
        .map(file => file.originFileObj as File);

      if (files.length === 0) {
        throw new Error('No valid files to process');
      }

      const { urls, error } = await uploadImages(files);
      
      if (error) throw error;

      // Process uploaded images with OCR
      const response = await axiosInstance.post('/api/process-images', {
        images: urls
      });

      if (response.data?.text) {
        setText(response.data.text);
      }
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      setUploading(false);
      setUploadProgress(0);
      setFileList([]);
    }
  }

  const modalTitle = () => {
    switch (step) {
      case 1:
        return 'New document'
    
      case 2:
        return 'Document settings'
    
      default:
        break;
    }
  }

  const footer = () => {
    if (step === 1) {
      return (
        <Flex className='w-full'>
          <Button key='cancel' onClick={handleCancel} className='round-2'>Cancel</Button>
          <Button key='continue' type='primary' disabled={!text} className='round-2 ml-auto' onClick={handleNextStep}>Continue</Button>
        </Flex>
      )
    } else {
      return (
        <Flex className='w-full'>
          <Button key='cancel' onClick={handleCancel} className='round-2'>Cancel</Button>
          <Button key='back' icon={<ArrowLeftOutlined />} onClick={() => setStep(1)} className='round-2 ml-auto mr-4'>Back</Button>
          <Button key='submit' type='primary' loading={loading} disabled={!title} onClick={handleSubmit} className='round-2'>Save</Button>
        </Flex>
      )
    }
  }

  const body = () => {
    if (step === 1) {
      return (
        <Flex vertical gap={16} className='export-modal'>
          <Text>Enter chinese text or upload images and we will generate translations, flashcards & exercises for you</Text>
          <TextArea 
            rows={16} 
            placeholder='Paste text here' 
            value={text} 
            onChange={(e) => setText(e.target.value)} 
            className='round-2' 
          />
          <Text>Or upload images</Text>
          <Dragger
            multiple
            fileList={fileList}
            beforeUpload={(file) => {
              if (!file) return Upload.LIST_IGNORE;
              
              if (!['image/jpeg', 'image/png'].includes(file.type)) {
                message.error(`${file.name} is not a valid image file`);
                return Upload.LIST_IGNORE;
              }
              if (file.size > 5 * 1024 * 1024) {
                message.error(`${file.name} is larger than 5MB`);
                return Upload.LIST_IGNORE;
              }
              // Create a new UploadFile object with the file as originFileObj
              const uploadFile: UploadFile = {
                uid: crypto.randomUUID(),
                name: file.name,
                status: 'done',
                size: file.size,
                type: file.type,
                originFileObj: file
              };
              setFileList(prev => [...prev, uploadFile]);
              return false;
            }}
            onRemove={file => {
              if (!file) return true;
              setFileList(prev => prev.filter(f => f.uid !== file.uid));
              return true;
            }}
          >
            <p className="ant-upload-drag-icon"><InboxOutlined /></p>
            <p className="ant-upload-text">Click or drag images to upload</p>
            <p className="ant-upload-hint">Support for JPEG/PNG files up to 5MB each (max 10 files)</p>
          </Dragger>
          {uploading && <Progress percent={uploadProgress} />}
          {fileList.length > 0 && (
            <Button 
              type="primary" 
              onClick={handleImageProcess} 
              loading={uploading}
              className='round-2'
            >
              Process Images
            </Button>
          )}
        </Flex>
      );
    } else {
      return (<Flex vertical gap={24} className='export-modal'>
        {loadingForTitle ? (
          <Spin className='my-20' />
        ) : (
          <>
            <Text>You can change auto generated title and description if you need</Text>
            <Flex vertical>
              <Title level={5}><span className='require-icon'>*</span>Title</Title>
              <Input value={title} onChange={(e) => setTitle(e.target.value)} className='round-2' required />
            </Flex>
            <Flex vertical gap={8}>
              <Title level={5} className='mb-0'>Choose additional materials to generate</Title>
              <Text>AI will generate study materials in up to 10 minutes. Select options:</Text>
              <Checkbox checked={materialOptions.translation} onChange={(e) => handleCheckboxChange(e, 'translation')} className='round-2'>Document translation</Checkbox>
              <Checkbox checked={materialOptions.pinyinAndDefinitions} onChange={(e) => handleCheckboxChange(e, 'pinyinAndDefinitions')} className='round-2'>Word definitions and pinyin</Checkbox>
              <Checkbox checked={materialOptions.audio} onChange={(e) => handleCheckboxChange(e, 'audio')} className='round-2'>Audio narration</Checkbox>
              <Checkbox checked={materialOptions.wordsAndPhrases} onChange={(e) => handleCheckboxChange(e, 'wordsAndPhrases')} className='round-2'>Key words & phrases</Checkbox>
              <Checkbox checked={materialOptions.exercises} onChange={(e) => handleCheckboxChange(e, 'exercises')} className='round-2'>Practice exercises</Checkbox>
            </Flex>
          </>
        )}
      </Flex>)
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      {contextHolder}
      <Modal
        open={open}
        title={modalTitle()}
        onCancel={handleCancel}
        footer={footer()}
        centered
      >
        {body()}
      </Modal>
    </Space>
  );
};

export default DocumentAddModal;