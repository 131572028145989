import { FeedType } from "../data/interfaces/feed";

export const getFeedDescriptionForType = (type: FeedType) => {
  switch (type) {
    case FeedType.TRANSLATE:
      return 'Read the text in Chinese and translate it into English to improve comprehension.';
    case FeedType.READ:
      return 'Practice your pronunciation by reading the text aloud and recording your voice.';
    case FeedType.ANSWER:
      return 'Read the Chinese text and answer the question in Chinese to enhance writing skills.';
    case FeedType.LISTEN_SPEAK:
      return 'Listen to the audio and repeat the text aloud to improve pronunciation.';
    case FeedType.WRITE:
      return 'Listen to the audio and write your answer in Chinese for better listening and writing practice.';
    case FeedType.PICK:
      return ' Listen to the audio and select all the correct options to test comprehension and decision-making.';
    case FeedType.SPEAK:
      return 'Read the question and record your response in Chinese to develop spoken fluency.';
    case FeedType.LISTEN_RESPOND:
      return 'Listen to the question and record your answer to combine listening and speaking practice.';
    case FeedType.ENGLISH_TO_CHINESE:
      return 'Translate the word from English to Chinese and write its pinyin to expand vocabulary.';
    case FeedType.CHINESE_TO_ENGLISH:
      return 'Translate the word from Chinese to English and write its pinyin for vocabulary retention.';
    default:
      return '';
  }
}

export const getFeedLabelForType = (type: FeedType) => {
  switch (type) {
    case FeedType.TRANSLATE:
      return 'Translate';
    case FeedType.READ:
      return 'Read Aloud';
    case FeedType.ANSWER:
      return 'Answer in Chinese';
    case FeedType.LISTEN_SPEAK:
      return 'Listen & Speak';
    case FeedType.WRITE:
      return 'Write What You Hear';
    case FeedType.PICK:
      return 'Pick the Right Choices';
    case FeedType.SPEAK:
      return 'Speak Your Thoughts';
    case FeedType.LISTEN_RESPOND:
      return 'Listen & Respond';
    case FeedType.ENGLISH_TO_CHINESE:
      return 'English to Chinese';
    case FeedType.CHINESE_TO_ENGLISH:
      return 'Chinese to English';
    default:
      return '';
  }
}
