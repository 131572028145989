import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Flex, Form, Input, message, Space, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { resetPassword } from '../../../utils/supabase';
import { app_url } from '../../../utils/const';
import '../auth.css';

const { Title, Text } = Typography;

const ForgotPasswordPage: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      await resetPassword(values.email, app_url + '/reset-password');
      setLoading(false)
      message.success('Password reset email sent. Check your inbox');
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  return (
    <Flex align='center' justify='center' className='flex-col h-full'>
      <Space className='flex-col bg-white form-header' align='center'>
        <Title className='mb-0' level={4}>Forgot password?</Title>
        <Form
          form={form}
          name='forgot-password'
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: 'The input is not valid email!' },
              { required: true, message: 'Please enter your email!' },
            ]}
            className='mb-4'
          >
            <Input prefix={<UserOutlined />} placeholder="Email.address@gmail.com" className='auth-element' />
          </Form.Item>
          <Form.Item className='mt-4 m-0'>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Send reset password link
            </Button>
          </Form.Item>
        </Form>
      </Space>
      <Flex align='center' justify='center' gap={8} className='mt-6'>
        <Link to='/login'>Return to Log In</Link>
      </Flex>
      <Text className='font-regular mb-0 text-gray mt-auto'>Copyright ©{new Date().getFullYear()}.</Text>
    </Flex>
  );
};

export default ForgotPasswordPage;