import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch } from "@reduxjs/toolkit";
import { AddNewTextDto } from "../data/interfaces/document";
import { clearAuth, setAuth } from "../store/authSlice";
import { supabase } from "../supabaseClient";
import { FeedType, FeedInterface } from '../data/interfaces/feed';

// Authorization
export const signUp = async (email: string, password: string, dispatch: Dispatch<AnyAction>) => {
  const { data: { user, session }, error } = await supabase.auth.signUp({ email, password });

  if (error) {
    throw error;
  }

  dispatch(setAuth({ user, session }))
}

export const signIn = async (email: string, password: string, dispatch: Dispatch<AnyAction>) => {
  const { data: { user, session }, error } = await supabase.auth.signInWithPassword({ email, password });

  if (error) {
    throw error;
  }

  if (!user || !session) {
    throw new Error('Authentication failed');
  }

  dispatch(setAuth({ user, session }));
}

export const resetPassword = async (email: string, redirectTo: string) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo: redirectTo });

  if (error) {
    throw error;
  }
}

export const updatePassword = async (password: string) => {
  const { error } = await supabase.auth.updateUser({ password: password });

  if (error) {
    throw error;
  }
}

export const signOut = async (dispatch: Dispatch<AnyAction>) => {
  await supabase.auth.signOut({
    scope: 'local'
  });

  dispatch(clearAuth());
}

export const checkAuth = async (dispatch: Dispatch<AnyAction>) => {
  const { data: { session } } = await supabase.auth.getSession();

  if (!session) {
    dispatch(clearAuth());
  }
}

// Document
export const addNewText = async (payload: AddNewTextDto): Promise<{ id: string }[]> => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase.from('documents').insert([payload]).select('id');

    if (error) {
      throw error;
    }

    return data;
  } else {
    return [];
  }
}

export const editText = async (payload: any, id: string) => {
  const { data, error } = await supabase.from('documents').update(payload).eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

export const getUserTexts = async () => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase.from('documents').select('*').order('created_at', { ascending: false });
  
    if (error) {
      throw error;
    }
    
    return data;
  } else {
    return [];
  }
}

export const getUserTextsForList = async () => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase
      .from('documents')
      .select('id, status, title, text, isFavorite')
      .eq('user_id', session.user.id)
      .order('created_at', { ascending: false });
  
    if (error) {
      throw error;
    }
    
    return data;
  } else {
    return [];
  }
}

export const getTextById = async (id: string) => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase.from('documents').select('*').eq('id', id);
  
    if (error) {
      throw error;
    }
  
    return data;
  } else {
    return [];
  }
}

export const getTextByIdForList = async (id: string) => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase.from('documents').select('id, status, title, text, isFavorite').eq('id', id);
  
    if (error) {
      throw error;
    }
  
    return data;
  } else {
    return [];
  }
}

export const deleteText = async (id: string) => {
  const { data, error } = await supabase.from('documents').delete().eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

export const setBookmark = async (id: string, pIndex: number) => {
  const { data, error } = await supabase.from('documents').update({bookmark: pIndex}).eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

export const getFlashcards = async () => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase.from('documents').select('id, title, flashcards').not('flashcards', 'is', null);
  
    if (error) {
      throw error;
    }
    
    return data;
  } else {
    return [];
  }
}

export const getAudioUrl = async (url: string) => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase.storage.from('users').createSignedUrl(`${session.user.id}/${url}`, 60);

    if (error) {
      return null;
    }
    
    return data.signedUrl;
  } else {
    return null;
  }
}

// Dictionary
export const getSearchResult = async (keyword: string) => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase.from('chinese_dictionary').select('*').eq('simplified', keyword);
  
    if (error) {
      throw error;
    }
  
    await addSearchResult(session.user.id, data.map((item: any) => {return {id: item.id, simplified: item.simplified, date: new Date() }}))
  
    return data;
  } else {
    return [];
  }
}

export const getSearchHistoryResult = async () => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase.from('dictionary_search_history').select('lookup_history').eq('user_id', session.user.id);
  
    if (error) {
      throw error;
    }
  
    return data;
  } else {
    return [];
  }
}

export const addSearchResult = async (userId: string, newResult: any[]) => {
  const { data } = await supabase.from('dictionary_search_history').select('user_id, lookup_history').eq('user_id', userId).single();
  
  if (data) {
    const updatedHistory = data.lookup_history ? [...data.lookup_history, ...newResult] : [...newResult];

    await supabase.from('dictionary_search_history').update({ lookup_history: updatedHistory }).eq('user_id', userId);
  } else {
    await supabase.from('dictionary_search_history').insert({ user_id: userId, lookup_history: [...newResult] })
  }
}

export const getWordDictionaryById = async (id: number) => {
  const { data, error } = await supabase.from('chinese_dictionary').select('*').eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

// Feed
interface RawFeedPost {
  id: number;
  user_id: string;
  post_type: string;
  post_data: any;
  created_at: string;
}

const transformFeedPost = (post: RawFeedPost): FeedInterface => {
  const basePost = {
    id: post.id.toString(),
    type: post.post_type as FeedType, // Assuming post_type matches FeedType enum values
  };

  // Add specific fields based on post_data content
  return {
    ...basePost,
    text: post.post_data.content,
    question: post.post_data.question,
    options: post.post_data.options,
    audio: post.post_data.audio_url,
    word: post.post_data.word,
  };
};

export const getFeedPosts = async () => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase
      .from('feed_posts')
      .select('*')
      .eq('user_id', session.user.id)
      .order('created_at', { ascending: false });
  
    if (error) {
      throw error;
    }
    
    // Transform the data before returning
    return data ? data.map(transformFeedPost) : [];
  } else {
    return [];
  }
}

export const getFeedPostById = async (id: string) => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase
      .from('feed_posts')
      .select('*')
      .eq('id', id)
      .single();
  
    if (error) {
      throw error;
    }
  
    // Transform single post
    return data ? transformFeedPost(data) : null;
  } else {
    return null;
  }
}

export interface UploadImagesResponse {
  urls: string[];
  error?: Error;
}

export const uploadImages = async (files: File[]): Promise<UploadImagesResponse> => {
  const { data: { session } } = await supabase.auth.getSession();

  if (!session) {
    return { urls: [], error: new Error('Not authenticated') };
  }

  try {
    const timestamp = new Date().toISOString();
    const folderPath = `${session.user.id}/images/${timestamp}`;
    const uploadedUrls: string[] = [];

    for (const file of files) {
      const fileName = `${crypto.randomUUID()}-${file.name}`;
      const filePath = `${folderPath}/${fileName}`;

      const { error } = await supabase.storage
        .from('users')
        .upload(filePath, file, {
          contentType: file.type,
          cacheControl: '3600',
          upsert: false
        });

      if (error) throw error;

      uploadedUrls.push(filePath);
    }

    return { urls: uploadedUrls };
  } catch (error) {
    return { 
      urls: [], 
      error: error instanceof Error ? error : new Error('Upload failed') 
    };
  }
}

// Conversation
export const createConversation = async (documentId: string) => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase
      .from('conversations')
      .insert({
        document_id: documentId,
        status: 'active',
        messages: []
      })
      .select('id')
      .single();

    if (error) {
      throw error;
    }

    return data;
  }
  return null;
};

export const updateConversation = async (
  conversationId: string, 
  messages: any[], 
  status: 'active' | 'completed' = 'completed'
) => {
  const { data, error } = await supabase
    .from('conversations')
    .update({
      messages,
      status,
      end_timestamp: status === 'completed' ? new Date().toISOString() : null
    })
    .eq('id', conversationId)
    .select();

  if (error) {
    throw error;
  }

  return data;
};

export const getConversationsByDocumentId = async (documentId: string) => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data, error } = await supabase
      .from('conversations')
      .select('*')
      .eq('document_id', documentId)
      .order('start_timestamp', { ascending: false });

    if (error) {
      throw error;
    }

    return data;
  }
  return [];
};
