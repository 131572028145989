import { useEffect, useState } from 'react';
import { Button, Flex, Modal, Typography, Input, Checkbox, Space, notification, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DocumentDtoForList, MaterialOptions, Status } from '../../data/interfaces/document';
import { editText } from '../../utils/supabase';

const { TextArea } = Input;
const { Text, Title } = Typography;

const DocumentEditModal = ({document, open, setOpen, callback}: {document: DocumentDtoForList | null, open: boolean, setOpen: (value: boolean) => void, callback: (payload: any) => void}) => {
  const [step, setStep] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [materialOptions, setMaterialOptions] = useState<MaterialOptions>({
    audio: true,
    exercises: true,
    translation: true,
    wordsAndPhrases: true,
    pinyinAndDefinitions: true,
  })
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (document) {
      setTitle(document.title);
      setText(document.text || '');
      setMaterialOptions({
        audio: document.status.audio === 'not_needed' ? false : true,
        exercises: document.status.exercises === 'not_needed' ? false : true,
        translation: document.status.translation === 'not_needed' ? false : true,
        wordsAndPhrases: document.status.wordsAndPhrases === 'not_needed' ? false : true,
        pinyinAndDefinitions: document.status.pinyinAndDefinitions === 'not_needed' ? false : true,
      })
    }
  }, [document])
  
  const handleCheckboxChange = (e: CheckboxChangeEvent, option: string) => {
    e.stopPropagation();
    setMaterialOptions((prev) => ({
      ...prev,
      [option]: e.target.checked,
    }))
  }

  const handleSubmit = async () => {
    try {
      if (document?.id) {
        const editedStatus: Status = {
          audio: materialOptions.audio ? 'needed' : 'not_needed',
          exercises: materialOptions.exercises ? 'needed' : 'not_needed',
          translation: materialOptions.translation ? 'needed' : 'not_needed',
          wordsAndPhrases: materialOptions.wordsAndPhrases ? 'needed' : 'not_needed',
          pinyinAndDefinitions: materialOptions.pinyinAndDefinitions ? 'needed' : 'not_needed',
        }
        const newDocumentDto = {
          title: title,
          text: text,
          status: editedStatus,
        }
    
        setLoading(true);
        await editText(newDocumentDto, document?.id);
        setLoading(false);
        setOpen(false);
        callback(newDocumentDto);
        // openNotification();
      }
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  // const openNotification = () => {
  //   api.open({
  //     message: 'Edit completed',
  //     description: (
  //       <Flex vertical gap={8}>
  //         <Text>Content edition for {title} completed:</Text>
  //         <Flex align='center'>
  //           {materialOptions.translation ? <CheckCircleOutlined style={{ color: '#52C41A' }} /> : <CloseCircleOutlined style={{ color: '#F5222D' }} />}
  //           <Text style={{ marginLeft: 8 }}>Document translation</Text>
  //         </Flex>
  //         <Flex align='center'>
  //           {materialOptions.pinyinAndDefinitions ? <CheckCircleOutlined style={{ color: '#52C41A' }} /> : <CloseCircleOutlined style={{ color: '#F5222D' }} />}
  //           <Text style={{ marginLeft: 8 }}>Word definitions and pinyin</Text>
  //         </Flex>
  //         <Flex align='center'>
  //           {materialOptions.audio ? <CheckCircleOutlined style={{ color: '#52C41A' }} /> : <CloseCircleOutlined style={{ color: '#F5222D' }} />}
  //           <Text style={{ marginLeft: 8 }}>Audio narration</Text>
  //         </Flex>
  //         <Flex align='center'>
  //           {materialOptions.wordsAndPhrases ? <CheckCircleOutlined style={{ color: '#52C41A' }} /> : <CloseCircleOutlined style={{ color: '#F5222D' }} />}
  //           <Text style={{ marginLeft: 8 }}>Key words and phrases</Text>
  //         </Flex>
  //         <Flex align='center'>
  //           {materialOptions.exercises ? <CheckCircleOutlined style={{ color: '#52C41A' }} /> : <CloseCircleOutlined style={{ color: '#F5222D' }} />}
  //           <Text style={{ marginLeft: 8 }}>Practice exercises</Text>
  //         </Flex>
  //       </Flex>
  //     ),
  //     btn: <Button type='primary' onClick={() => api.destroy('open' + {Date})}>Ok</Button>,
  //     key: 'open' + {Date},
  //   })
  // }

  const modalTitle = () => {
    switch (step) {
      case 1:
        return 'Edit text?'
    
      case 2:
        return 'Edit text'
    
      case 3:
        return 'Document settings'
    
      default:
        break;
    }
  }

  const footer = () => {
    switch (step) {
      case 1:
        return (
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} className='round-2'>Cancel</Button>
            <Button key='continue' type='primary' className='round-2 ml-auto' onClick={() => setStep(2)}>Edit</Button>
          </Flex>
        )

      case 2:
        return (
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} className='round-2'>Cancel</Button>
            <Button key='continue' type='primary' disabled={!text} className='round-2 ml-auto' onClick={() => setStep(3)}>Continue</Button>
          </Flex>
        )

      case 3:
        return [
          <Button key='cancel' onClick={() => setOpen(false)} className='round-2'>Cancel</Button>,
          <Button key='continue' type='primary' loading={loading} disabled={!title} onClick={handleSubmit} className='round-2'>Save</Button>
        ]
          
      default:
        break;
    }
  }

  const body = () => {
    switch (step) {
      case 1:
        return (<Flex vertical gap={24} className='export-modal'>
          <Text>Once you edit the text, new data will be generated based on the updated content, and any previous data will be lost. This action cannot be undone.</Text>
        </Flex>)

      case 2:
        return (<Flex vertical gap={16} className='export-modal'>
          <Text>Enter chinese text and we will generate translations, flashcards & exercises for you</Text>
          <TextArea rows={16} placeholder='Paste text here' value={text} onChange={(e) => setText(e.target.value)} className='round-2' />
        </Flex>)

      case 3:
        return (<Flex vertical gap={24} className='export-modal'>
          <Text>You can change auto generated title and description if you need</Text>
          <Flex vertical>
            <Title level={5}><span className='require-icon'>*</span>Title</Title>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} className='round-2' required />
          </Flex>
          <Flex vertical gap={8}>
            <Title level={5} className='mb-0'>Choose additional materials to generate</Title>
            <Text>AI will generate study materials in up to 10 minutes. Select options:</Text>
            <Checkbox checked={materialOptions.translation} onChange={(e) => handleCheckboxChange(e, 'translation')} className='round-2'>Document translation</Checkbox>
            <Checkbox checked={materialOptions.pinyinAndDefinitions} onChange={(e) => handleCheckboxChange(e, 'pinyinAndDefinitions')} className='round-2'>Word definitions and pinyin</Checkbox>
            <Checkbox checked={materialOptions.audio} onChange={(e) => handleCheckboxChange(e, 'audio')} className='round-2'>Audio narration</Checkbox>
            <Checkbox checked={materialOptions.wordsAndPhrases} onChange={(e) => handleCheckboxChange(e, 'wordsAndPhrases')} className='round-2'>Key words & phrases</Checkbox>
            <Checkbox checked={materialOptions.exercises} onChange={(e) => handleCheckboxChange(e, 'exercises')} className='round-2'>Practice exercises</Checkbox>
          </Flex>
        </Flex>)
    
      default:
        break;
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      {contextHolder}
      <Modal
        open={open}
        title={modalTitle()}
        onCancel={() => setOpen(false)}
        footer={footer()}
        centered
      >
        {body()}
      </Modal>
    </Space>
  );
};

export default DocumentEditModal;