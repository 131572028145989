import React from 'react';
import { Layout as AntLayout } from 'antd';
import HeaderComponent from './Header';
import "./layout.css";

const { Content } = AntLayout;

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="layout">
      <HeaderComponent />
      {children}
    </div>
  );
};

export default Layout;
