import { useState } from "react";
import { Button, Divider, Dropdown, Flex, Input, MenuProps, Typography } from "antd";
import { AudioOutlined, CheckCircleFilled, MoreOutlined, UndoOutlined } from "@ant-design/icons";
import AudioPlayer from "../common/AudioPlayer";
import ScoreButton from "./ScoreButton";
import { FeedInterface, FeedType } from "../../data/interfaces/feed";
import { getFeedDescriptionForType, getFeedLabelForType } from "../../utils/feed";

const { Title } = Typography;

interface FeedCardRProps {
  feed: FeedInterface;
  result?: boolean;
}  

const FeedCard = ({ feed, result }: FeedCardRProps) => {
  const [answer, setAnswer] = useState<string>('');
  const [subAnswer, setSubAnswer] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [mode, setMode] = useState<number>(0);

  const moreItems: MenuProps['items'] = [
    {
      key: 1,
      label: 'Settings'
    },
  ];

  const handleOptionClick = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const answerElement = () => {
    switch (feed.type) {
      case FeedType.TRANSLATE:
        return <Input value={answer} placeholder="Enter translation" className='round-2' onChange={(e) => setAnswer(e.target.value)} />

      case FeedType.ANSWER:
        return <Input value={answer} placeholder="Answer the question" className='round-2' onChange={(e) => setAnswer(e.target.value)} />

      case FeedType.WRITE:
        return <Input value={answer} placeholder="Enter what you've heard" className='round-2' onChange={(e) => setAnswer(e.target.value)} />
    
      case FeedType.PICK:
        return (
          <Flex gap={20} align="center" className="options-grid">
            {feed.options?.map((option, index) => (
              <Button 
                key={index} 
                icon={selectedOptions.includes(option) && <CheckCircleFilled />}
                className={`feed-option round-2 ${selectedOptions.includes(option) ? 'selected' : ''}`}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </Button>
            ))}
          </Flex>
        )
    
      case FeedType.ENGLISH_TO_CHINESE:
      case FeedType.CHINESE_TO_ENGLISH:
        return (
          <Flex gap={20} align="center" className="w-full">
            <Input value={answer} placeholder="Enter translation" className='round-2 w-full' onChange={(e) => setAnswer(e.target.value)} />
            <Input value={subAnswer} placeholder="Enter pinyin" className='round-2 w-full' onChange={(e) => setSubAnswer(e.target.value)} />
          </Flex>
        )
    
      default:
        return <></>
    }
  }

  const resultElement = () => {
    switch (feed.type) {
      case FeedType.TRANSLATE:
      case FeedType.ANSWER:
      case FeedType.WRITE:
        return <Title level={5} className="mb-0 font-weight-light">{answer}</Title>

      case FeedType.READ:
      case FeedType.LISTEN_SPEAK:
      case FeedType.SPEAK:
      case FeedType.LISTEN_RESPOND:
        return <AudioPlayer url={'ddd'} />

      default:
        return <></>
    }
  }

  const isAnswerDisabled = () => {
    switch (feed.type) {
      case FeedType.PICK:
        return selectedOptions.length === 0;
      case FeedType.ENGLISH_TO_CHINESE:
      case FeedType.CHINESE_TO_ENGLISH:
        return !answer.trim() || !subAnswer.trim();
      default:
        return !answer.trim();
    }
  };

  const submitAnswer = () => {
    setMode(1);
  }

  return (
    <Flex vertical className='w-full feed-card'>
      <Flex justify="space-between" align="center" className="w-full">
        <Title level={3} className="mb-0">{getFeedLabelForType(feed.type)}</Title>
        <div onClick={(e) => e.stopPropagation()} className="clickable">
          <Dropdown menu={{items: moreItems}} trigger={['click']}>
            <MoreOutlined rotate={90} className='font-24' />
          </Dropdown>
        </div>
      </Flex>
      <Title level={5} className="mb-0 font-weight-light">{getFeedDescriptionForType(feed.type)}</Title>
      <Flex vertical className="w-full" gap={20}>
        {feed.text && <Title level={4} className="mb-0 font-weight-light mt-0">{feed.text}</Title>}
        {feed.audio && <AudioPlayer url={feed.audio} />}
        {feed.word && (
          <Flex className="feed-word">
            <Title level={3} className="mb-0">{feed.word}</Title>
          </Flex>
        )}

        <Divider className="my-0" />

        {feed.question && <Title level={4} className="mb-0 font-weight-light mt-0">{feed.question}</Title>}

        {!mode ? (
          <Flex gap={20} align="center" justify="space-between" className="w-full trans-col">
            {answerElement()}

            {(feed.type === FeedType.READ || feed.type === FeedType.LISTEN_SPEAK || feed.type === FeedType.SPEAK || feed.type === FeedType.LISTEN_RESPOND) ? (
              <Button type="primary" icon={<AudioOutlined />} className='round-0 ml-auto' onClick={submitAnswer}>
                Record Answer
              </Button>
            ) : (
              <Button type="primary" className='round-0' disabled={isAnswerDisabled()} onClick={submitAnswer}>
                Answer
              </Button>
            )}
          </Flex>
        ) : (
          <Flex gap={20} align="center" justify="space-between" className="w-full trans-col">
            <Title level={5} className="mb-0 font-weight-light">{resultElement()}</Title>
            <Flex gap={20} align="center" className="ml-auto">
              <ScoreButton type="score" score={36} />
              <UndoOutlined className="redo-icon" />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default FeedCard;