import { useEffect, useState } from "react";
import { Collapse, CollapseProps, Flex, Typography } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import { DictionaryDto } from "../../data/interfaces/dictionary";

const { Title, Text } = Typography;

const ResultCard = ({result}: {result: DictionaryDto}) => {
  const [items, setItems] = useState<CollapseProps['items']>([]);

  useEffect(() => {
    if (result) {
      setItems(result.enriched_llm?.pronunciations.map((p, index) => {
        return {
          key: index, 
          label: (
            <Title level={5} style={{ marginBottom: 0 }}>
              {`${result.simplified} ${p.pinyin} `}
              {p.audioUrl && <SoundOutlined className='sound-icon' />}
            </Title>
          ), 
          children: meaning(p)}
      }))
    }
  }, [result])

  const meaning = (meanings: any) => (
    <Flex vertical className='w-full meaning-container' gap={12}>
      {meanings.translations.map((t: any, index: number) => (
        <Flex key={index} vertical>
          <Title level={5} className="mb-2">{index + 1}. {t.english} &nbsp;&nbsp; <span className="phrase-text">{t.partOfSpeech}</span></Title>
          <Flex vertical className="pl-4">
            <Text>{t.additionalInfo}</Text>
            <br />
            {t.examples && t.examples.length > 0 && (
              <>
                <Title level={5} className="mb-0">Examples:</Title>
                {t.examples.map((e: any, ind: number) => (
                  <Flex key={ind} className='detail-set'>
                    <Text className="text-blue">{` ${e.chinese} `}</Text>
                    <Text>{e.pinyin}&nbsp;</Text>
                    <Text className="m-0 font-weight-bold">- {e.english}</Text>
                  </Flex>
                ))}
              </>
            )}
            <br />
            {t.synonyms && t.synonyms.length > 0 && (
              <>
                <Title level={5} style={{ marginBottom: 0 }}>Synonyms:</Title>
                {t.synonyms.map((e: any, ind: number) => (
                  <Flex key={ind} className='detail-set'>
                    <Text>
                      <span className="text-blue">{` ${e.word} `}</span>
                      {e.pinyin}&nbsp;-&nbsp;
                    </Text>
                    <Text className="m-0 font-weight-bold">{e.english}</Text>
                  </Flex>
                ))}
              </>
            )}
            <br />
            {t.antonyms && t.antonyms.length > 0 && (
              <>
                <Title level={5} style={{ marginBottom: 0 }}>Antonyms:</Title>
                {t.antonyms.map((e: any, ind: number) => (
                  <Flex key={ind} className='detail-set'>
                    <Text>
                      <span className="text-blue">{` ${e.word} `}</span>
                      {e.pinyin}&nbsp;-&nbsp;
                    </Text>
                    <Text className="m-0 font-weight-bold">{e.english}</Text>
                  </Flex>
                ))}
              </>
            )}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )

  return (
    <>
      {items && items.length > 0 ? (
        <Collapse 
          ghost 
          items={items} 
          className="w-full"
          defaultActiveKey={items?.map((_, i) => i.toString())}
        />
      ) : <></>}
    </>
  )
}

export default ResultCard;