import { useNavigate } from "react-router-dom";
import { Empty, Flex } from "antd";
import { DictionaryDto } from "../../data/interfaces/dictionary";
import ResultCard from "./ResultCard";

const SearchResult = ({searchResult}: {searchResult: DictionaryDto[]}) => {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(`/dictionary/${id}`);
  }

  if (searchResult.length === 0) {
    return <Empty description="Sorry we couldn't find any result" className="my-12" />;
  } else {
    return (
      <>
        {searchResult?.map((result, index) => (
          <Flex 
            key={index} 
            className="dictionary-result p-0"
            justify="space-between" 
            // onClick={() => handleClick(result.id)}
          >
            <ResultCard result={result} />
          </Flex>
        ))}
      </>
    )
  }
}

export default SearchResult;