import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Flex, Row, Space, Col, Typography, Input, Button, Divider } from 'antd';
import SubHeader from '../../components/layout/SubHeader';
import OvercastIcon from "../../assets/icons/overcast.svg";
import SpotifyIcon from "../../assets/icons/spotify.svg";
import AppleIcon from "../../assets/icons/apple.svg";
import PocketIcon from "../../assets/icons/pocket.svg";
import AndroidIcon from "../../assets/icons/android.svg";
import QRIcon from "../../assets/QR.svg";
import "./index.css";

const { Title, Link, Text } = Typography;

const AppCard = ({icon, label, onClick, selected}: {icon: string, label: string, onClick: () => void, selected?: boolean}) => {
  return (
    <Flex align='center' className={`app-card ${selected && 'selected'}`} gap={8} onClick={onClick}>
      <img src={icon} alt='app' />
      <Text>{label}</Text>
    </Flex>
  )
}

const appList = [
  {
    icon: OvercastIcon,
    label: 'Overcast'
  },
  {
    icon: SpotifyIcon,
    label: 'Spotify'
  },
  {
    icon: AppleIcon,
    label: 'Apple Podcast'
  },
  {
    icon: PocketIcon,
    label: 'Pocket Casts'
  },
  {
    icon: AndroidIcon,
    label: 'Android'
  },
]

const ProfilePage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [selectedApp, setSelectedApp] = useState<string>('Overcast');

  const handleAppSelect = (value: string) => {
    setSelectedApp(value);
  }

  return (
    <Row className='w-full flex-col'>
      <SubHeader>
        <Title level={4} className='mb-0'>Account</Title>
      </SubHeader>
      <Row className='container detail-header mx-0' gutter={24}>
        <Space className='bg-white w-full flex-col round-8'>
          <Flex vertical className={`w-full ${isMobile ? 'p-4' : 'p-6'}`} gap={16}>
            <Title level={5}>Personal Information</Title>
            <Row gutter={[24, 16]}>
              <Col xs={24} sm={12}>
                <Flex vertical className='w-full'>
                  <Title level={5}>Name</Title>
                  <Input value={name} className='account-element' onChange={(e) => setName(e.target.value)} />
                </Flex>
              </Col>
              <Col xs={24} sm={12}>
                <Flex vertical className='w-full'>
                  <Title level={5}>Email</Title>
                  <Input value={email} className='account-element' onChange={(e) => setEmail(e.target.value)} />
                </Flex>
              </Col>
            </Row>
            <Button type='primary'>Update Information</Button>
          </Flex>
          <Divider className='m-0' />
          <Row gutter={[24, 16]}>
            <Col xs={24} sm={12}>
              <Flex vertical className={`w-full ${isMobile ? 'p-4' : 'p-6'}`} gap={16}>
                <Title level={5}>Where do you want to receive podcasts?</Title>
                <Flex vertical className='w-full'>
                  <Title level={5}>Email</Title>
                  <Input value={email} className='account-element' onChange={(e) => setEmail(e.target.value)} />
                </Flex>
                <Flex vertical className='w-full'>
                  <Title level={5}>RSS</Title>
                  <Flex align='center'>
                    <Input value={email} className='account-element mr-4' onChange={(e) => setEmail(e.target.value)} />
                    <Link href='' className='noWrap'>Copy Link</Link>
                  </Flex>
                </Flex>
                <Flex vertical className='w-full'>
                  <Title level={5}>Podcast</Title>
                  <Flex align='center'>
                    <Input value={email} className='account-element mr-4' onChange={(e) => setEmail(e.target.value)} />
                    <Link href='' className='noWrap'>Copy Link</Link>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
            <Col xs={24} sm={12}>
              <Flex vertical className={`w-full ${isMobile ? 'p-4' : 'p-6'}`} gap={16}>
                <Title level={5}>Get in the app</Title>
                <Flex vertical className='w-full'>
                  <Title level={5}>Select App</Title>
                  <Row gutter={[{ xs: 16, md: 24 }, 16]} className='w-full mx-0'>
                    {appList.map((app, index) => (
                      <Col xs={12} xl={8} key={index}>
                        <AppCard icon={app.icon} label={app.label} onClick={() => handleAppSelect(app.label)} selected={selectedApp === app.label} />
                      </Col>
                    ))}
                  </Row>
                </Flex>
                <Flex gap={16} align='center' className='mt-4'>
                  <img src={QRIcon} alt='QR code' />
                  <Flex vertical>
                    <Title level={5}>Scan this QR code with your phone</Title>
                    <Link href='' className='noWrap'>Open Link</Link>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Space>
      </Row>
    </Row>
  );
};

export default ProfilePage;