import { ReactNode } from 'react';
import { Flex } from 'antd';
import './layout.css';

interface RProps {
  children: ReactNode
}

const SubHeader = ({ children }: RProps) => {

  return (
    <Flex vertical className='w-full container bg-white sub-header' gap={8}>
      {children}
    </Flex>
  );
};

export default SubHeader;
