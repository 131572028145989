import { useEffect, useState } from 'react';
import { Button, Flex, Modal, Typography, Checkbox, Space, message, CollapseProps, Collapse } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import FlashCard from './FlashCard';
import { DocumentDto } from '../../data/interfaces/document';
import axiosInstance from '../../utils/axiosInstance';
import { formatDateTime } from '../../utils/dictionary';

const { Text } = Typography;

interface RProps {
  open: boolean, 
  setOpen: (value: boolean) => void, 
  documents: DocumentDto[]
}

const EXPORT_TIMEOUT = 30000; // 30 seconds

const BulkExportModal = ({ open, setOpen, documents }: RProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, React.Key[]>>({});
  const [items, setItems] = useState<CollapseProps['items']>([]);

  useEffect(() => {
    const temp: CollapseProps['items'] = [];
    documents.map((doc) => {
      const words = doc.flashcards;
  
      if (words && words.length > 0) {
        temp.push({
          key: doc.id,
          label: (
            <Flex>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={(selectedRows[doc.id] || []).length === words.length}
                indeterminate={(selectedRows[doc.id] || []).length > 0 && (selectedRows[doc.id] || []).length < words.length}
                onChange={(e: CheckboxChangeEvent) => handleSelectAll(e.target.checked, doc.id)}
                onClick={(e) => e.stopPropagation()}
              />
              {doc.title}
            </Flex>
          ),
          extra: <Text>{words.length} word{words.length > 1 ? 's' : ''}</Text>,
          children: <FlashCard doc={doc} words={words} getRowSelection={getRowSelection} selectable  />
        })
      }

      return doc;
    })
    setItems(temp);
  }, [documents, selectedRows])
  
  useEffect(() => {
    if (open) {
      const initialSelection: Record<string, React.Key[]> = {};
      documents.forEach((doc) => {
        if (doc.flashcards && doc.flashcards.length > 0) {
          initialSelection[doc.id] = doc.flashcards.map((card) => {
            // Add a console.log to see what data we have
            console.log('Card data:', card);
            
            return JSON.stringify({
              text: card.word,
              partOfSpeech: card.part_of_speech,
              translation: card.short_definitions?.join(' '),
              pinyin: card.pinyin  // Make sure this exists in card
            });
          });
        }
      });
      setSelectedRows(initialSelection);
    } else {
      setSelectedRows({});
    }
  }, [open, documents]);

  const handleSelectAll = (selected: boolean, itemKey: string) => {
    const selectedDoc = documents.find(d => d.id === itemKey);
    if (selectedDoc && selectedDoc.flashcards) {
      const newSelectedRows = { ...selectedRows };
      newSelectedRows[itemKey] = selected ? selectedDoc.flashcards.map((item) => JSON.stringify({text: item.word, partOfSpeech: item.part_of_speech, translation: item.short_definitions?.join(' '), pinyin: item.pinyin})) : [];
      setSelectedRows(newSelectedRows);
    }
  };

  const handleRowSelect = (selectedRowKeys: React.Key[], itemKey: string) => {
    setSelectedRows((prev) => ({
      ...prev,
      [itemKey]: selectedRowKeys
    }))
  };

  const getRowSelection = (itemKey: string) => ({
    onSelectAll: (selected: boolean) => handleSelectAll(selected, itemKey),
    onChange: (selectedRowKeys: React.Key[]) => handleRowSelect(selectedRowKeys, itemKey),
    selectedRowKeys: selectedRows[itemKey] || [],
  });

  const handleSubmit = async () => {
    try {
      const wordsList = Object.values(selectedRows).flat();
      setLoading(true);
      
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), EXPORT_TIMEOUT);

      try {
        // Log the parsed data to verify pinyin is present
        const words = wordsList.map(s => {
          const parsed = JSON.parse(s as string);
          console.log('Parsed word data:', parsed);
          
          return {
            text: parsed.text,
            partOfSpeech: parsed.partOfSpeech,
            translation: parsed.translation,
            pinyin: parsed.pinyin
          };
        });

        console.log('Final words data:', words);

        const response = await axiosInstance.post('/api/create-deck', 
          { 
            deckName: formatDateTime(), 
            words
          },
          { signal: controller.signal }
        );

        clearTimeout(timeoutId);

        if (response && typeof response === 'object' && 'data' in response) {
          const { signedURL } = response.data as any;
          if (signedURL) {
            const anchor = document.createElement('a');
            anchor.href = signedURL;
            anchor.download = '';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          } else {
            throw new Error('File URL is missing in the response.');
          }
        }
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          throw new Error('Request timed out after 30 seconds. The server might be busy processing the deck creation. Please try again or reduce the number of selected cards.');
        }
        throw error;
      }

      setLoading(false);
      setOpen(false);
      setSelectedRows({});
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        title='Export Flashcards'
        onCancel={() => setOpen(false)}
        footer={(
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} className='round-2'>Cancel</Button>
            <Button key='continue' type='primary' className='round-2 ml-auto' loading={loading} onClick={handleSubmit}>Export</Button>
          </Flex>
        )}
        centered
      >
        <Flex vertical gap={16} className='export-modal'>
          <Collapse items={items} className='round-0' />
        </Flex>
      </Modal>
    </Space>
  );
};

export default BulkExportModal;