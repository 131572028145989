import { useState, useRef, useEffect } from 'react';
import { Slider, Button, Tooltip, Flex, Popover } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, SoundOutlined, MoreOutlined } from '@ant-design/icons';

const AudioPlayer = ({ url }: {url: string}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audio = audioRef.current;

    const updateTime = () => {
      if (audio) {
        setCurrentTime(audio.currentTime);
      }
    };

    const setAudioData = () => {
      if (audio) {
        setDuration(audio.duration);
      }
    };

    audio?.addEventListener('timeupdate', updateTime);
    audio?.addEventListener('loadedmetadata', setAudioData);

    return () => {
      audio?.removeEventListener('timeupdate', updateTime);
      audio?.removeEventListener('loadedmetadata', setAudioData);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSliderChange = (value: number) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = value;
      setCurrentTime(value);
    }
  };

  const handleVolumeChange = (value: number) => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = value / 100;
      setVolume(value / 100);
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <Flex align='center' justify='space-between' className='w-full audio-player-container'>
      <audio ref={audioRef} src={url} />
      <Flex gap={12} align='center' className='w-full'>
        <Button
          icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
          onClick={togglePlayPause}
          className='play-icon'
        />
        <Flex gap={12} align='center' className='audio-element'>
          <span>{formatTime(currentTime)}</span>
          <Slider
            min={0}
            max={duration}
            value={currentTime}
            onChange={handleSliderChange}
            tooltipVisible={false}
            className="w-full"
          />
          <span>{formatTime(duration)}</span>
        </Flex>
        <Popover 
          content={<Tooltip title={`Volume: ${Math.round(volume * 100)}%`}>
            <Slider
              min={0}
              max={100}
              value={volume * 100}
              onChange={handleVolumeChange}
              tooltipVisible={false}
              className='volume-slider'
            />
          </Tooltip>}
          trigger='click'
        >
          <SoundOutlined className="volume-icon" />
        </Popover>
        <MoreOutlined className='cursor-pointer' />
      </Flex>
    </Flex>
  );
};

export default AudioPlayer;
