import { Flex, Tag } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

type RProps = {
  id: number;
  label: string;
  prop: string;
  status?: string;
}

const StatusTag = ({tag}: {tag: RProps}) => {
  
  const getTagStyle = (status?: string) => {
    switch (status) {
      case 'not_needed':
        return { border: '1px solid #FFA39E', background: '#FFF1F0', color: '#F5222D', borderRadius: 2, width: 'fit-content' }
    
      case 'needed':
        return { border: '1px solid #91D5FF', background: '#E6F7FF', color: '#1890FF', borderRadius: 2, width: 'fit-content' }
    
      case 'completed':
        return { border: '1px solid #B7EB8F', background: '#F6FFED', color: '#52C41A', borderRadius: 2, width: 'fit-content' }
    
      default:
        return { border: '1px solid #FFA39E', background: '#FFF1F0', color: '#F5222D', borderRadius: 2, width: 'fit-content' }
    }
  }

  const getTagIcon = (status?: string) => {
    switch (status) {
      case 'not_needed':
        return <CloseCircleOutlined />
    
      case 'needed':
        return <ClockCircleOutlined />
    
      case 'completed':
        return <CheckCircleOutlined />
    
      default:
        return <CloseCircleOutlined />
    }
  }

  return (
    <Tag style={getTagStyle(tag.status)}>
      <Flex align='center' gap={4}>
        {getTagIcon(tag.status)}
        {tag.label}
      </Flex>
    </Tag>
  );
};

export default StatusTag;