import { Link } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const AuthForm = ({type, handleSubmit, loading}: {type: string, handleSubmit: (values: any) => void, loading: boolean}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      name={type}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item
        name="email"
        rules={[
          { type: 'email', message: 'The input is not valid email!' },
          { required: true, message: 'Please enter your email!' },
        ]}
        className='mb-4'
      >
        <Input prefix={<UserOutlined />} placeholder="Email.address@gmail.com" className="auth-element" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: 'Please enter your password!' },
        ]}
        hasFeedback
        className='mb-4'
      >
        <Input.Password prefix={<LockOutlined />} placeholder={type === 'login' ? "Password" : 'Create Password'} className="auth-element" />
      </Form.Item>
      {type === 'login' ? (
        <Link to="/forgot-password" className='mb-4'>Forgot password?</Link>
      ) : (
        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
          className='mb-4'
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" className="auth-element" />
        </Form.Item>
      )}
      <Form.Item className='mt-4 m-0'>
        <Button type="primary" htmlType="submit" loading={loading} block className="round-2">
          {type === 'login' ? 'Sign In' : 'Create an account'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AuthForm;