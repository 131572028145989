import React from 'react';
import { useWebRTC } from '../hooks/useWebRTC';
import { DocumentDto } from '../data/interfaces/document';
import './AIChatSidebar.css';

interface AIChatSidebarProps {
  onClose: () => void;
  document?: DocumentDto;
}

export const AIChatSidebar: React.FC<AIChatSidebarProps> = ({ onClose, document }) => {
  const { status, cleanup, messages } = useWebRTC({
    documentContext: document ? { 
      text: document.text, 
      title: document.title,
      id: document.id
    } : undefined
  });

  const handleClose = () => {
    cleanup();
    onClose();
  };

  const statusDescriptions: Record<string, string> = {
    connecting: 'Connecting to AI...',
    connected: 'Connected - Ready...',
    listening: 'Listening...',
    processing: 'Processing...',
    error: 'Connection failed. Please try again.',
    disconnected: 'Initializing...'
  };

  return (
    <>
      <div className="ai-sidebar-overlay" onClick={handleClose} />
      <div className="ai-sidebar">
        <div className={`status-indicator ${status}`}>
          {statusDescriptions[status] || 'Ready...'}
        </div>
        
        <div className="messages-container">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              {message.content}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}; 