import { Button, Flex, Modal, Typography, Space } from 'antd';
import { DataTypeForCardTable } from '../../data/interfaces/flashcard';

const { Text } = Typography;

const FlashcardDeleteModal = ({word, open, setOpen, loading, handleSubmit}: {word: DataTypeForCardTable | undefined, open: boolean, setOpen: (value: boolean) => void, loading: boolean, handleSubmit: () => void}) => {

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        title={`Delete ${word?.word}?`}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key='cancel' onClick={() => setOpen(false)} className='round-2'>Cancel</Button>,
          <Button key='delete' type='primary' loading={loading} onClick={handleSubmit} className='round-2'>Delete</Button>
        ]}
        centered
      >
        <Flex vertical gap={24} className='export-modal'>
          <Text>Are you sure you want to delete document? This actions can't be undone</Text>
        </Flex>
      </Modal>
    </Space>
  );
};

export default FlashcardDeleteModal;