import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Button, Tag, Flex, Dropdown, MenuProps, Typography } from 'antd';
import { ClockCircleOutlined, CloseCircleOutlined, MoreOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import StatusTag from './StatusTag';
import { DocumentDtoForList } from '../../data/interfaces/document';

const { Title } = Typography

type Tag = {
  id: number;
  label: string;
  prop: string;
  status?: string;
}

const tagItems: Tag[] = [
  {
    id: 1,
    label: 'Pinyin',
    prop: 'pinyinAndDefinitions'
  },
  {
    id: 2,
    label: 'English',
    prop: 'translation'
  },
  {
    id: 3,
    label: 'Audio',
    prop: 'audio'
  },
  {
    id: 4,
    label: 'Flashcards',
    prop: 'flashcards'
  },
  {
    id: 5,
    label: 'Exercises',
    prop: 'exercises'
  },
]

const StyledCard = styled(Card)`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  width: 100%;

  &.error {
    background: linear-gradient(0deg, #FFF1F0 0%, #FFF1F0 100%), var(--Miscellaneous-Floating-Tab---Pill-Fill, #FFF);
    pointer-events: none;
  }
  
  &.disable {
    background: linear-gradient(0deg, rgba(230, 247, 255, 0.50) 0%, rgba(230, 247, 255, 0.50) 100%), var(--Miscellaneous-Floating-Tab---Pill-Fill, #FFF);
    pointer-events: none;

    .clickable {
      pointer-events: auto;
    }
  }

  &:hover {
    background: #F5F5F5;
  }
`

const DocumentCard = ({document, goToDetail, handleEdit, handleFavorite, deleteText}: {document?: DocumentDtoForList, goToDetail: (id: string) => void, handleEdit: (doc?: DocumentDtoForList) => void, handleFavorite: (id: string) => void, deleteText: (doc?: DocumentDtoForList) => void}) => {
  const [tags, setTags] = useState<Tag[]>([]);

  const moreItems: MenuProps['items'] = [
    {
      key: 1,
      label: 'Settings'
    },
    {
      key: 2,
      label: 'Edit text',
      onClick: () => handleEdit(document)
    },
    {
      key: 3,
      label: 'Delete',
      onClick: () => deleteText(document)
    }
  ];

  useEffect(() => {
    if (document) {
      setTags(tagItems.map((t) => ({
        ...t,
        status: document.status[t.prop as keyof typeof document.status] || ''
      })))
    }
  }, [document])

  if (!document) {
    return null;
  }

  const isDocumentCompleted = (): boolean => {
    return Object.values(document.status).some(value => value === 'completed');
  }

  const isDocumentError = (): boolean => {
    return Object.values(document.status).some(value => value === 'error');
  }

  const handleFavoriteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleFavorite(document.id);
  }

  return (
    <StyledCard
      styles={{ body: { gap: '.5rem', display: 'flex', padding: '1rem 1.5rem', flexDirection: 'column' } }}
      className={isDocumentError() ? 'error' : isDocumentCompleted() ? '' : 'disable'}
      onClick={() => goToDetail(document.id)}
    >
      <Flex justify='space-between' align='center'>
        <Title level={4} className='more w-half mb-0'>
          {isDocumentError() && <CloseCircleOutlined className='text-red mr-2' />}
          {!isDocumentError() && !isDocumentCompleted() && <ClockCircleOutlined className='text-blue-2 mr-2' />}
          <span style={(!isDocumentCompleted() || isDocumentError()) ? {opacity: '.2'} : {}}>{document?.title}</span>
        </Title>
        <Flex align='center' gap={16}>
          <div onClick={handleFavoriteClick}>
            {!document?.isFavorite ? <StarOutlined className='font-24' /> : <StarFilled className='text-yellow font-24' />}
          </div>
          <div onClick={(e) => e.stopPropagation()} className="clickable">
            <Dropdown menu={{items: isDocumentCompleted() ? moreItems : moreItems.slice(2)}} trigger={['click']}>
              <MoreOutlined rotate={90} className='font-24' />
            </Dropdown>
          </div>
        </Flex>
      </Flex>
      <Flex>
        {tags.slice(0, 3).map((tag) => <StatusTag key={tag.id} tag={tag} />)}
        <div onClick={(e) => e.stopPropagation()}>
          <Dropdown menu={{items: tags.slice(3, 5).map((t) => ({key: t.id, label: <StatusTag tag={t} />}))}} trigger={['click']}>
            <Button size="small">+2</Button>
          </Dropdown>
        </div>
      </Flex>
    </StyledCard>
  );
};

export default DocumentCard;