import { useState } from 'react';
import { Button, Flex, Modal, Space, message } from 'antd';
import FlashCard from '../flashcards/FlashCard';
import { DocumentDto } from '../../data/interfaces/document';
import axiosInstance from '../../utils/axiosInstance';
import { formatDateTime } from '../../utils/dictionary';

interface RProps {
  open: boolean, 
  setOpen: (value: boolean) => void, 
  doc: DocumentDto
}

const EXPORT_TIMEOUT = 30000; // 30 seconds

const SingleDocumentExportModal = ({ open, setOpen, doc }: RProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>(() => doc.flashcards?.map(card => JSON.stringify({
    text: card.word,
    partOfSpeech: card.part_of_speech,
    translation: card.short_definitions?.join(' '),
    pinyin: card.pinyin
  })) || []);

  const getRowSelection = (itemKey: string) => ({
    onChange: (selectedRowKeys: React.Key[]) => setSelectedRows(selectedRowKeys),
    selectedRowKeys: selectedRows,
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), EXPORT_TIMEOUT);

      try {
        const response = await axiosInstance.post('/api/create-deck', 
          { 
            deckName: formatDateTime(), 
            words: selectedRows.map(s => JSON.parse(s as string)) 
          },
          { signal: controller.signal }
        );

        clearTimeout(timeoutId);

        if (response && typeof response === 'object' && 'data' in response) {
          const { signedURL } = response.data as any;

          if (signedURL) {
            const anchor = document.createElement('a');
            anchor.href = signedURL;
            anchor.download = '';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          } else {
            throw new Error('File URL is missing in the response.');
          }
        }
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          throw new Error('Request timed out after 10 seconds. The server might be busy processing the deck creation. Please try again or reduce the number of selected cards.');
        }
        throw error;
      }

      setLoading(false);
      setOpen(false);
      setSelectedRows([]);
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        title='Export Flashcards'
        onCancel={() => setOpen(false)}
        footer={(
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} className='round-2'>Cancel</Button>
            <Button key='continue' type='primary' className='round-2 ml-auto' loading={loading} onClick={handleSubmit}>Export</Button>
          </Flex>
        )}
        centered
      >
        <Flex vertical gap={16} className='export-modal'>
          <FlashCard doc={doc} words={doc.flashcards || []} getRowSelection={getRowSelection} selectable  />
        </Flex>
      </Modal>
    </Space>
  );
};

export default SingleDocumentExportModal;