import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Breadcrumb, Button, Flex, message, Row, Space, Typography } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import SubHeader from '../../../components/layout/SubHeader';
import FlashCard from '../../../components/flashcards/FlashCard';
import FlashcardDeleteModal from '../../../components/flashcards/FlashcardDeleteModal';
import SingleDocumentExportModal from '../../../components/documents/SingleDocumentExportModal';
import Spinner from '../../../components/layout/Spinner';
import { DataTypeForCardTable } from '../../../data/interfaces/flashcard';
import { DocumentDto } from '../../../data/interfaces/document';
import { editText, getTextById } from '../../../utils/supabase';

const { Title } = Typography;

const DocumentFlashcardsPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { id } = useParams();
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [openDeleteWordModal, setOpenDeleteWordModal] = useState<boolean>(false);
  const [document, setDocument] = useState<DocumentDto | undefined>(undefined);
  const [loadingForDelete, setLoadingForDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedWord, setSelectedWord] = useState<DataTypeForCardTable>();

  const breadcrumbItems = [
    {
      href: '/documents',
      title: 'Library'
    },
    {
      href: `/documents/${document?.id}`,
      title: document?.title
    },
    {
      title: 'Flashcards'
    }
  ]

  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    try {
      if (id) {
        const response = await getTextById(id);
        if (response) {
          setDocument(response[0]);
        }
      }
    } catch (error) {
      message.error((error as Error).message)
    }
    setLoading(false);
  };

  if (!document) {
    return <></>
  }

  const handleCardDelete = (value: DataTypeForCardTable) => {
    setSelectedWord(value);
    setOpenDeleteWordModal(true);
  }

  const handleDeleteSubmit = async () => {
    try {
      if (selectedWord) {
        document.flashcards = document.flashcards?.filter(f => f.id !== selectedWord.id);
        setLoadingForDelete(true);
        await editText(document, document.id);
        setLoadingForDelete(false);
        setOpenDeleteWordModal(false);
        fetchData();
      }
    } catch (error) {
      message.error((error as Error).message);
      setLoadingForDelete(false);
    }
  }

  return (
    <Row className='w-full'>
      <SubHeader>
        <Breadcrumb items={breadcrumbItems} />
        <Flex align='center' justify='space-between'>
          <Title level={4} className='mb-0'>Flashcards</Title>
          <Button type='primary' icon={<ExportOutlined />} className='round-0' onClick={() => setOpenExportModal(true)}>Export</Button>
        </Flex>
      </SubHeader>

      <Spinner loading={loading}>
        {document.flashcards && (
          <Flex className='container'>
            <Space className={`bg-white w-full ${!isMobile && 'p-6 round-8'}`}>
              <FlashCard doc={document} words={document.flashcards} handleCardDelete={handleCardDelete} />
            </Space>
          </Flex>
        )}
      </Spinner>

      <SingleDocumentExportModal 
        open={openExportModal} 
        setOpen={setOpenExportModal} 
        doc={document} 
      />
      <FlashcardDeleteModal open={openDeleteWordModal} setOpen={setOpenDeleteWordModal} word={selectedWord} loading={loadingForDelete} handleSubmit={handleDeleteSubmit} />
    </Row>
  );
};

export default DocumentFlashcardsPage;