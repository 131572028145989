import { Flex, Typography } from "antd";
import { DeleteOutlined, FileAddOutlined } from "@ant-design/icons";
import StatusTag from "./StatusTag";
import { Word } from "../../data/interfaces/document";

const { Title, Text } = Typography;

const successTag = {
  id: 0,
  label: 'Added to Flashcards',
  prop: '',
  status: 'completed'
}

interface RProps {
  word: Word, 
  handleFlashcard: (word: Word, adding: boolean) => void, 
  isFlashcard: number
}

const DictionaryPop = ({word, handleFlashcard, isFlashcard}: RProps) => {
  return (
    <Flex className="flex-col word-popup" gap={6}>
      {isFlashcard === 1 && <StatusTag tag={successTag} />}
      <Flex gap={8} align="center">
        <Title level={5} className="mb-0">{word.text}</Title>
      </Flex>
      <Title level={5} className="m-0">{word.partOfSpeech}</Title>
      <Title level={5} className="m-0">{word.pinyin}</Title>
      <Title level={5} className="m-0">{word.translation}</Title>
      <Flex className="flex-col word-action" gap={8} align="start">
        {isFlashcard !== 1 ? (
          <Flex align="center" gap={8} className="cursor-pointer" onClick={() => handleFlashcard(word, true)}>
            <FileAddOutlined />
            <Text>Add to flashcards</Text>
          </Flex>
        ) : (
          <Flex align="center" gap={8} className="cursor-pointer" onClick={() => handleFlashcard(word, false)}>
            <DeleteOutlined />
            <Text>Remove from flashcards</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default DictionaryPop;