import React, { useEffect, useState } from 'react';
import { Row, Input, Typography, Flex, message } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import SubHeader from '../../components/layout/SubHeader';
import Spinner from '../../components/layout/Spinner';
import FeedCard from '../../components/feed/FeedCard';
import { FeedInterface } from '../../data/interfaces/feed';
import { getFeedPosts } from '../../utils/supabase';
import './index.css';
import axios from 'axios';
import { supabase } from '../../supabaseClient';

const { Title } = Typography;
const { Search } = Input;

const FeedPage: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [searchTextLabel, setSearchTextLabel] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [feedPosts, setFeedPosts] = useState<FeedInterface[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      setFeedPosts([]);
    };
  }, []);

  const fetchData = async () => {
    try {
      try {
        await axios.get('/api/feed_posts', {
          headers: {
            'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
          }
        });
      } catch (error: any) {
        if (error.response?.status !== 404) {
          throw error;
        }
      }

      const response = await getFeedPosts();
      if (response) {
        setFeedPosts(response);
      }
    } catch (error) {
      message.error((error as Error).message);
    }
    setLoading(false);
  };

  const onSearch = (value: string) => {
    setSearchText(value);
    // Implement your search logic here
  };

  const handleClear = () => {
    setSearchText("");
    setSearchTextLabel("");
  };

  return (
    <Row className='w-full'>
      <SubHeader>
        <Flex className='justify-between align-center'>
          <Title level={4} className='mb-0'>Feed</Title>
          <Flex gap={24} align='center'>
            <Flex className='relative'>
              <Search placeholder='Search' value={searchTextLabel} onChange={(e) => setSearchTextLabel(e.target.value)} onSearch={onSearch} className='search-bar' />
              {searchText && 
                <CloseCircleFilled className='close-icon' onClick={handleClear} />}
            </Flex>
          </Flex>
        </Flex>
      </SubHeader>

      <Spinner loading={loading}>
        <Row gutter={[{ xs: 16, md: 24 }, { xs: 16, sm: 24 }]} className='container w-full mx-0'>
          {feedPosts
            .filter((post) => post.text?.includes(searchText) || post.question?.includes(searchText))
            .map((post) => (
              <FeedCard key={post.id} feed={post} />
          ))}
        </Row>
      </Spinner>
    </Row>

  )
}

export default FeedPage;