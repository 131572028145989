import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Flex, message, Row, Space, Typography } from 'antd';
import SubHeader from '../../../components/layout/SubHeader';
import ResultCard from '../../../components/dictionary/ResultCard';
import Spinner from '../../../components/layout/Spinner';
import { DictionaryDto } from '../../../data/interfaces/dictionary';
import { getWordDictionaryById } from '../../../utils/supabase';
import './index.css';

const { Title, Text } = Typography;

const DictionaryDetailPage: React.FC = () => {
  const { id } = useParams();
  const [wordDetail, setWordDetail] = useState<DictionaryDto>();
  const [loading, setLoading] = useState<boolean>(true);

  const breadcrumbItems = [
    {
      href: '/dictionary',
      title: 'Dictionary'
    },
    {
      title: wordDetail?.simplified
    }
  ]

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id])

  const fetchData = async (id: string) => {
    try {
      const response = await getWordDictionaryById(parseInt(id));
      if (response) {
        setWordDetail(response[0]);
      }
    } catch (error) {
      message.error((error as Error).message)
    }
    setLoading(false);
  }

  return (
    <Row className='w-full'>
      <SubHeader>
        <Breadcrumb items={breadcrumbItems} />
        {wordDetail && (
          <>
            <Title level={4} className='mb-0'>
              {`${wordDetail.simplified} ${wordDetail.pinyin ?? ''} `} 
            </Title>
            <Text>HSK 3.0: {wordDetail.hsk_3_0} &nbsp;&nbsp; Frequency: {wordDetail.frequency}</Text>
          </>
        )}
      </SubHeader>

      <Spinner loading={loading}>
        <Flex className='container w-full'>
          <Space className='bg-white w-full dictionary-container'>
            {wordDetail && <ResultCard result={wordDetail} />}
          </Space>
        </Flex>
      </Spinner>
    </Row>
  );
};

export default DictionaryDetailPage;