import { useNavigate } from "react-router-dom";
import { Empty, Flex, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { GroupedSearchHistory } from "../../data/interfaces/dictionary";

const { Title, Text } = Typography;

const SearchHistory = ({searchHistory}: {searchHistory: GroupedSearchHistory[]}) => {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(`/dictionary/${id}`);
  }

  if (searchHistory.length === 0) {
    return <Empty description="You haven't got previous search results" className="my-12" />;
  } else {
    return (
      <>
        {searchHistory?.map((s, index) => (
          <Flex key={index} vertical className="w-full">
            <Flex className="dictionary-category">
              <Text type='secondary'>{s.date}</Text>
            </Flex>
            {s.results.map((result, index) => (
              <Flex key={index} className="dictionary-result" justify="space-between" onClick={() => handleClick(result.id)}>
                <Title level={5} className="mb-0">
                  {result.simplified}
                </Title>
                <RightOutlined />
              </Flex>
            ))}
          </Flex>
        ))}
      </>
    )
  }
}

export default SearchHistory;