import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface ScoreButtonRProps {
  type: 'score' | 'success';
  score?: number;
  success?: boolean;
}

const ScoreButton = ({type, score, success}: ScoreButtonRProps) => {

  const evaluateResult = () => {
    if (type === 'score' && score) {
      return score >= 50;
    } else if (type === 'success') {
      return success;
    }
  }

  return (
    <Button icon={evaluateResult() ? <CheckCircleOutlined /> : <CloseCircleOutlined />} className={evaluateResult() ? 'success' : 'fail'}>
      {type === 'score' ? `${score}%` : success ? 'Successful' : 'Failed'}
    </Button>
  )
}

export default ScoreButton;
